import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GalleryContrafotografiaCovers from "../components/galleryContrafotografiaCovers"

const Contrafotografia = () => (
  <Layout>
    <SEO title="Contrafotografía" />
    <h1>An underground photography small press</h1>
    <p>Nearly a hundred photographers published, thousands of zines sold. This underground small press started to publish in 2018 and it's a non profit project. This won't change and it won't ever be stopped. Check the project <a href="https://contrafotografia.com" target="__blank">here</a></p>
    <a href="https://contrafotografia.com" target="__blank">
	    <div class="gallery big">
	    	<GalleryContrafotografiaCovers />
	    </div>
	</a>
  </Layout>
)

export default Contrafotografia
