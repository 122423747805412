import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const GalleryContrafotografiaCovers = () => {
	const gallery = useStaticQuery(graphql`
	    query {
	        data: allFile(filter: {relativeDirectory: {eq: "contrafotografia-covers"}}) {
	          edges {
	            node {
	              childImageSharp {
			          fluid(maxWidth: 800) {
			             ...GatsbyImageSharpFluid
			        }
			      }
	            }
	          }
	        }
	      }
	 `)

	var imagesGallery = []

	for (var i = gallery.data.edges.length - 1; i >= 0; i--) {
		imagesGallery.push(<Img fluid={gallery.data.edges[i].node.childImageSharp.fluid} />)
	}

  return imagesGallery
}

export default GalleryContrafotografiaCovers